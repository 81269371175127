/* eslint-disable no-param-reassign */
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import SEO from '../views/components/SEO';
import Hero from '../views/layouts/Hero';
import MainLayout from '../views/layouts/MainLayout';

const NotFoundPage = ({
    data,
    location,
    pageContext: { locale, translations },
}) => {
    if (locale === undefined) {
        if (typeof window !== `undefined`) {
            locale =
                sessionStorage.getItem(`lang`) !== null
                    ? sessionStorage.getItem(`lang`)
                    : `fr`;
        } else {
            locale = `fr`;
        }
    }
    return (
        <MainLayout
            locale={locale}
            translationsPaths={translations}
            neverTransparent
        >
            <SEO
                title='pages.p404.headerTitle'
                description='pages.p404.description'
                location={location}
                translationsPaths={translations}
                pageType='404'
            />
            <Hero
                image={data.hero.childImageSharp.gatsbyImageData}
                title='pages.p404.title'
                colors={data.hero.fields.colors}
                text1='pages.p404.description'
                textColorDark
                titleColorDark
            />
            {/* <FormattedMessage id='pages.p404.title' tagName='h1' />
            <FormattedMessage id='pages.p404.description' tagName='p' /> */}
            {/* <LocalizedLink to='/'>
                <FormattedMessage id='pages.page2.link' />
            </LocalizedLink> */}
        </MainLayout>
    );
};

NotFoundPage.propTypes = {
    data: PropTypes.shape({
        hero: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string,
        translations: PropTypes.array.isRequired,
    }).isRequired,
};

export default NotFoundPage;

export const pageQuery = graphql`
    query {
        hero: file(
            sourceInstanceName: { eq: "hero" }
            relativePath: { eq: "404.jpg" }
        ) {
            ...BgImages
        }
    }
`;
